<template>
    <div class="notfound">
        <div class="error-main">
            <h1>Oops! Page Not found...</h1>
            <div class="error-heading">404</div>
            <p class="pa-10">ບໍ່ພົບໜ້າທີ່ທ່ານຄົ້ນຫາ ....</p>

            <router-link to="/">  ກັບຄືນ</router-link>
        </div>
    </div>

</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style>
    .notfound{
        font-family: 'Ropa Sans', sans-serif;
        margin-top: 30px;

        text-align: center;
        color: #000;
    }
    .error-heading{
        margin: 50px auto;
        width: 250px;
        border: 5px solid #fff;
        font-size: 126px;
        line-height: 126px;
        border-radius: 30px;
        /* text-shadow: 6px 6px 5px #000; */
    }
    .error-heading img{
        width: 100%;
    }
    .error-main h1{
        font-size: 72px;
        margin: 0px;
        color: #F3661C;
        /* text-shadow: 0px 0px 5px #fff; */
    }
</style>