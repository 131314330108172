<template>
    <div >
        <LoaderComponent/>
        <router-view  v-slot="{ Component }" :key="$router.path">
            <transition name="fade" mode="out-in">
                <component :is="Component"></component>
            </transition>
        </router-view>
    </div>


</template>

<script>
    import LoaderComponent from "@/components/Loader";
    export default {
        components: {LoaderComponent}
    }
</script>

<style lang="css">
    .fade-enter-from {
        opacity: 0;
        transform: translateX(100px);
    }

    .fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .fade-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }

    .fade-leave-active {
        transition: all 0.3s ease-in;
    }
</style>
