<template>
    <v-app id="inspire">
        <v-navigation-drawer app v-model="drawer " class="elevation-1">

            <v-list-item @click="$router.push({name:'home'}, () => {})">
                <v-list-item-content>

                    <v-list-item-title class="text-h6">
                        <v-avatar
                                color="primary"
                                size="40"
                        >
                            <img
                                    src="../assets/apa.png"
                                    alt="APA"
                            >
                        </v-avatar>
                        PAYMENT API
                    </v-list-item-title>

                </v-list-item-content>

            </v-list-item>

            <v-divider></v-divider>

            <v-list
                    dense
                    nav
            >
                <v-list-item
                        v-for="item in items"
                        :key="item.title"
                        :to="item.to"
                        link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app light color="white" class="elevation-3">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>ພົງສະຫວັນປະກັນໄພ (ເອພີເອ)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
                    left
                    bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            to="#"
                    >
                        <v-icon>person</v-icon>
                        <v-list-item-content>
                            <v-list-item-title class="pl-3">
                                ຂໍ້ມູນສ່ວນຕົວ
                            </v-list-item-title>
                        </v-list-item-content>


                    </v-list-item>
                    <v-list-item
                            @click="logout"
                    >
                        <v-icon>logout</v-icon>
                        <v-list-item-content>

                            <v-list-item-title class="pl-3">ອອກຈາກລະບົບ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main>
            <div class="pa-5">
<!--                <Transition mode="out-in">-->
<!--                    <router-view />-->
<!--                </Transition>-->
                <router-view/>


            </div>

        </v-main>
    </v-app>
</template>

<script>

    export default {
        name: "MainLayout",
        data: () => ({
            drawer: null,
            items: [
                {title: 'ໜ້າຫຼັກ', icon: 'dashboard', to: '/home'},
                {title: 'ລາຍການຄ້າງຈ່າຍ', icon: 'list', to: '/list'},
                {title: 'ລາຍງານ', icon: 'mdi-chart-line', to: '/report'},
                {title: 'ຊ່ວຍເຫຼືອ', icon: 'help', to: '/test'},
            ],
            BLOCK_UI:false
        }),
        computed: {
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated
            }
        },
        methods: {
             logout() {

                 this.$store.dispatch('LogOut')


            }
        },
    }
</script>
<style scoped>

    .fade-enter-active, .fade-leave-active {
        transition: opacity .4s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
