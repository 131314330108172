
class AuthService {
    login(user) {
        return window.axios.post( '/login', user)
    }

    logout() {
        localStorage.removeItem('token');
    }

}

export default new AuthService();