<template>
    <v-app id="inspire">
        <v-main>

            <v-container fluid fill-height>
<!--                <LoaderComponent/>-->
                <v-layout align-center justify-center>
                    <v-flex xs12 sm10 md5 xl3>
                        <v-card class="elevation-6">
                            <v-toolbar height="100px" dark color="green darken-4">
                                <v-row>
                                    <v-col xs12>
                                        <v-img position="center center" width="300" src="../assets/apa-insurance.png"></v-img>
                                    </v-col>

                                </v-row>

                            </v-toolbar>
                            <v-card-text class="pa-8">
                                <Transition>
                                    <v-alert v-if="showErrors" class="pa-3"
                                             type="error" outlined
                                    >ຊື່ຜູ້ໃຊ້ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ
                                    </v-alert>
                                </Transition>


                                <v-form>
                                    <v-text-field
                                            prepend-icon="person"
                                            name="login"
                                            color="warning"
                                            label="ຊື່ຜູ້ໃຊ້"
                                            type="text"
                                            v-model="userData.username"
                                            outlined
                                    ></v-text-field>
                                    <v-text-field
                                            id="password"
                                            color="warning"
                                            prepend-icon="lock"
                                            name="password"
                                            label="ລະຫັດຜ່ານ"
                                            type="password"
                                            v-model="userData.password"
                                            outlined
                                    ></v-text-field>
                                </v-form>

                            </v-card-text>
                            <v-card-actions>

                                <v-btn @click="submitLogin" block color="green darken-4" large dark>ເຂົ້າສູ່ລະບົບ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import {mapActions} from 'vuex';


    export default {
        name: 'LoginPage',
        data() {
            return {

                userData: {
                    username: '',
                    password: ''
                },
                showErrors: false,
                BLOCKUI: false,
            }
        },
        methods: {
            ...mapActions(["LogIn"]),
            async submitLogin() {
                try {

                    let data = await this.LogIn(this.userData)

                    localStorage.setItem('token', data.token)
                    this.$store.commit('setToken', data.token)
                    this.$router.push({path: '/home'});
                    this.showErrors = false

                } catch (error) {
                    this.showErrors = true
                    setTimeout(()=>{
                        this.showErrors=false
                    },3000)
                }


            }
        }


    };
</script>

<style scoped>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 1s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>
