import AuthService from '../../services/Auth.Service';
import router from "@/router";
const state = {
    token:  localStorage.getItem('token') ||  '',

};
const getters = {
    isAuthenticated: state => !!state.token,
    token: state => state.token,
};
const actions = {
     LogIn({commit}, LoginData) {
       return   AuthService.login(LoginData)

    },
    async LogOut({commit}){
        let token = ''
        commit('LogOut', token)
    }
};
const mutations = {
    setToken(state, token){
        state.token=token

    },

    LogOut(state){
        state.token = ''
        AuthService.logout()
         router.push('/login')


    },
};
export default {
    state,
    getters,
    actions,
    mutations
};