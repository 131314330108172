import Vue from 'vue'
import  './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "@/assets/style.css"
import vueNumeralFilterInstaller from 'vue-numeral-filter';


Vue.use(vueNumeralFilterInstaller);

Vue.use(require('vue-moment'));

/* axios.defaults.withCredentials = false
axios.defaults.baseURL = 'http://localhost:7000/api/';
const  accessToken  =  localStorage.getItem('token')

if (accessToken) {
  axios.defaults.headers.common['Authorization'] =  accessToken
}

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

      originalRequest._retry = true;
      store.dispatch('LogOut')
      return router.push('/login')
    }
  }
})
 */
Vue.config.productionTip = false



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
