<template>
  <div class="about">
    <Transition>
      <v-alert v-if="notification"
               type="error"
      >
        ກະລຸນາເລືອກລາຍການ
      </v-alert>
    </Transition>

    <v-dialog
            v-model="confirm_dialog"
            max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ຢືນຢັນ?
        </v-card-title>

        <v-card-text>
          <p class="pt-4">
            ຢືນຢັນຈ່າຍລາຍການທັງໝົດທີ່ເລືອກ
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
                  color="red"
                  text
                  @click="confirm_dialog = false"
          >
            ຍົກເລີກ
          </v-btn>

          <v-btn
                  color="green darken-1"
                  text
                  @click="getCheckedItem"
          >
            ຢືນຢັນ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-row>
          <div class="pa-2">
            <v-btn color="green darken-2" dark @click="OpenDialog" class="pa-1"><v-icon>mdi-checkbox-marked-circle-outline</v-icon> ຈ່າຍເງີນລາຍການທີ່ເລືອກ</v-btn>
          </div>
          <div class="pa-2">
            <v-btn color="error" @click="getCheckedItem" class="pa-1"><v-icon>mdi-beaker-remove-outline</v-icon> ຍົກເລີກລາຍການທີ່ເລືອກ</v-btn>
          </div>
        </v-row>

        <v-spacer></v-spacer>
        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ຄົ້ນຫາ"
                single-line
                hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
              :headers="headers"
              :items="itemsWithIndex"
              :footer-props="{
                'items-per-page-options': [20,50]
              }"
              :items-per-page="20"
              class="elevation-2"
              :search="search"
              show-select
              v-model="selected"


      >

        <template v-slot:[`item.created_date`]="{ item }">
          <span>{{ item.created_date | moment('DD/MM/YYYY') }}</span>
        </template>
        <template v-slot:[`item.actual_amount`]="{ item }">
          <span class="green--text text--darken-4" >{{ item.actual_amount | numeral('0,0.00') }} {{item.currency_unit}}</span>
        </template>
        <template v-slot:[`item.discount_amount`]="{ item }">
          <span >{{ item.discount_amount | numeral('0,0.00') }} {{item.currency_unit}}</span>
        </template>
        <template v-slot:[`item.total_premium`]="{ item }">
          <span class="red--text">{{ item.total_premium | numeral('0,0.00') }} {{item.currency_unit}}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editTutorial(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteTutorial(item)" class="red--text">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>
<script>

  export default {

    data () {
      return {
        selected:[],
        confirm_dialog:false,
        notification:false,
        search:'',
        headers: [
          {text: '#',align: 'center',sortable: false,value: 'index'},
          { text: 'ເລກທີ່ສັນຍາ', align: 'start', sortable: true, value: 'policy_id'},
          { text: 'ຊື່ລູກຄ້າ', value: 'client_name' },
          { text: 'ເບີໂທ', value: 'client_tel' },
          { text: 'ປະເພດ', value: 'type_name' },
          { text: 'ຄ່າປະກັນໄພລວມ', value: 'actual_amount',align: 'right' },
          { text: 'ສ່ວນຫຼຸດພິເສດ', value: 'discount_amount',align: 'right' },
          { text: 'ຄ່າປະກັນໄພຕ້ອງຈ່າຍ', value: 'total_premium',align: 'right' },
          { text: 'ວັນທີ່ຂາຍ', value: 'created_date' },
          { text: 'ດຳເນີນການ',sortable: false, value: 'actions' },
        ],
        List_item: [],
      }
    },
    mounted(){
      this.getList('N')
    },
    computed: {
      itemsWithIndex() {
        return this.List_item.map(
                (List_item, index) => ({
                  ...List_item,
                  index: index + 1
                }))
      }
    },
    methods:{
     async getList(status){
        try {
          let res= await window.axios.get('/List/'+status)
          this.List_item=res

        }catch (e) {
          console.log(e)
        }
      },
      editTutorial(item){
        console.log(item.policy_id)
      },
      OpenDialog(){

        if(this.selected.length>0){
          this.confirm_dialog=true
        }else {
          this.notification=true
          setTimeout(()=>{
            this.notification=false
          },3000)
        }
      },
      getCheckedItem(){
        let item=this.selected.map(e => e.policy_id)
        console.log(item)
        this.confirm_dialog=false
      }
    }


  }
</script>
<style>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 1s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>