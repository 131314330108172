<template>
    <v-overlay :value="isLoading" z-index="3">
        <v-progress-circular
                indeterminate
                size="64"

        ></v-progress-circular>
    </v-overlay>
</template>

<script>
    import {EventBus} from "@/helper/EventBus"
    export default {
        name: "LoaderComponent",
        data(){
            return ({
                isLoading:false
            })
        },
        created() {
            EventBus.$on("toggle",(boolean)=>{
                this.isLoading=boolean
                }
            )
        }
    }
</script>
