import Vue from 'vue'
import store from '../store';
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/ListView.vue';
import LoginPage from "@/views/LoginPage";
import MainLayout from "@/views/MainLayout";
import NotFound from "@/views/NotFound";
import PaymentReport from "@/views/PaymentReport";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children:[
      {
        path: '/',
        component: HomeView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path:'/home',
        name:'home',
        component:HomeView,
        meta:{
          requiresAuth:true
        }
      },
      {
        path:'/list',
        component:AboutView,
        meta:{
          requiresAuth:true
        }
      },
      {
        path:'/report',
        component:PaymentReport,
        meta:{
          requiresAuth:true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta:{
      requiresAuth:false
    }

  },
    {
        path: '**',
        name: 'error',
        component: NotFound
    }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: AboutView
  //   route level code-splitting
  //   this generates a separate chunk (about.[hash].js) for this route
  //   which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ListView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('token');

  if (to.meta.requiresAuth && loggedIn) {


    // router.push('/login')
    return   next();
  }else if(!to.meta.requiresAuth){
    return  next();

    // router.push('/home')
  }else {
   return  next('/login');
  }


})

export default router
