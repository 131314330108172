<template>
    <div class="pa-5 elevation-5">
        <h3>ລາຍງານການຈ່າຍເງິນ</h3>
        <v-divider/>
        <Transition>
            <v-alert
                    v-if="show_error"
                    dense
                    outlined
                    type="error"
            >
                ກະລຸນາໃສ່ຊ່ວງວັນທີ່ໃຫ້ຖືກຕ້ອງ
            </v-alert>
        </Transition>

        <v-form>
            <v-row class="pt-5">
                <v-col cols="12" sm="4" md="3" xl="2" xs="12">

                    <v-dialog
                            ref="date_picker_start"
                            v-model="show_datepicker_start"
                            :return-value.sync="start_date"
                            persistent
                            width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    :value="report_start_date"
                                    label="ຕັ້ງແຕ່ວັນທີ່"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="start_date"
                                color="green darken-4"
                                scrollable

                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    dark
                                    color="red"
                                    @click="show_datepicker_start = false"
                            >
                                ຍົກເລີກ
                            </v-btn>
                            <v-btn
                                    dark
                                    color="green darken-4"
                                    @click="$refs.date_picker_start.save(start_date)"
                            >
                                ຕົກລົງ
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" xs="12">
                    <v-dialog
                            ref="date_picker_end"
                            v-model="show_datepicker_end"
                            :return-value.sync="end_date"
                            persistent
                            width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    :value="report_end_date"
                                    label="ເຖີງວັນທີ່"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"

                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="end_date"
                                color="green darken-4"
                                scrollable

                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    dark
                                    color="red"
                                    @click="show_datepicker_end = false"
                            >
                                ຍົກເລີກ
                            </v-btn>
                            <v-btn
                                    dark
                                    color="green darken-4"
                                    @click="$refs.date_picker_end.save(end_date)"
                            >
                                ຕົກລົງ
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" xs="12">
                    <v-btn @click="getReport">
                        <v-icon>search</v-icon>
                        ຄົ້ນຫາ
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row>
            <v-data-table
                    :headers="headers"
                    :items="search_data"
                    :footer-props="{
                'items-per-page-options': [20,50]
              }"
                    :items-per-page="20"
                    :item-class="Highlightreverse"
                    no-data-text="ບໍ່ພົບຂໍ້ມູນ"


            >
                <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ item.amount | numeral('0,0.00') }} {{item.currency_unit}}</span>
                </template>
                <template v-slot:[`item.created_date`]="{ item }">
                    <span>{{ item.created_date | moment('DD/MM/YYYY hh:mm:ss A') }}</span>
                </template>
            </v-data-table>

        </v-row>
    </div>
</template>

<script>

    export default {
        name: "PaymentReport",
        data: () => ({
            start_date: null,
            end_date: null,
            show_datepicker_start: false,
            show_datepicker_end: false,
            show_error:false,
            search_data: [],
            headers: [
                {text: 'ເລກທີ່ສັນຍາ', align: 'start', sortable: true, value: 'policy_id'},
                {text: 'ຊື່ລູກຄ້າ', value: 'client_name'},
                {text: 'ເບີໂທ', value: 'client_tel'},
                {text: 'ຈຳນວນ', value: 'amount', align: 'right'},
                {text: 'ລາຍລະອຽດ', value: 'description'},
                {text: 'ວັນທີ່ຈ່າຍ', value: 'created_date', align: 'center'},
                {text: 'P/R', value: 'tran_type'},
                {text: 'ເລກທີ່ອ້າງອີງ', value: 'transaction_ref'},

            ],
        }),
        methods: {
            formatDate(date) {

                return date ? this.$moment(date).format('DD/MM/YYYY') : ''
            },
            async getReport() {
                if(this.start_date==null || this.end_date==null){
                    this.show_error=true

                    setTimeout(()=>{
                        this.show_error=false
                    },3000)
                    return
                }
                try {
                    let res = await window.axios.post('/report/payment', {
                        start_date: this.start_date,
                        end_date: this.end_date
                    })
                    // console.log(res)
                    this.search_data = res
                } catch (e) {
                    console.log(e)
                }


            },
            Highlightreverse(item) {
                return item.amount < 0 ? 'red--text text-darken-1' : ''
            }
        },
        computed: {
            report_start_date() {
                return this.formatDate(this.start_date)
            },
            report_end_date() {
                return this.formatDate(this.end_date)
            }
        }
    }
</script>

<style scoped>
    .v-data-table {
        width: 100%;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 1s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>