<template>
  <div class="home pa-10 elevation-2">
    <h3>ຍອດຂາຍແຕ່ລະເດືອນ</h3>
    <v-row >
      <v-col md="8" xl="8" xs="12" sm="10" >
        <BarChart :chart-data="chart_statistic"/>
      </v-col>
      <v-col md="4" xl="4" xs="12" sm="2">
        <PieChart/>
      </v-col>

<!--      <v-col md="6" xl="6">-->
<!--        <LineChart :chart-data="chart_statistic"/>-->
<!--      </v-col>-->
<!--      <v-col md="6" xl="6">-->
<!--        <DoughnutChart/>-->
<!--      </v-col>-->

    </v-row>


  </div>
</template>

<script>
// @ is an alias to /src
import BarChart from "@/views/BarChart";
import PieChart from "@/views/PieChart";
// import LineChart from "@/views/LineChart";
// import DoughnutChart from "@/views/DoughnutChart";


export default {
  name: 'HomeView',
  components: {

    // DoughnutChart,
    // LineChart,
    BarChart,
    PieChart,
  },
  data(){
    return {
      chart_statistic:{
        labels: [ 'Jan', 'Feb', 'Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ],
        datasets: [
          {
            label: 'MOTOR',
            // lineTension: 0,
            // borderWidth: 1,
            // borderColor: '#F2A727',
            // pointBackgroundColor: '#F2A727',
            backgroundColor: '#bed0ed',
            data: []
          },
          {
            label: 'PA',
            // lineTension: 0,
            // borderWidth: 1,
            // borderColor: '#F2A727',
            // pointBackgroundColor: '#F2A727',
            backgroundColor: '#F2A727',
            data: []
          }
        ]

      }
    }
  },

   mounted() {
    this.getChartData()
  },
  methods:{
   async getChartData()
    {
      try {

        let res= await window.axios.get('/Report')
        this.chart_statistic.datasets[0].data=res.MT
        this.chart_statistic.datasets[1].data=res.PA

      }catch (e) {

        console.log(e)
      }
    }
  }

}
</script>
