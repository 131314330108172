"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store'
import {EventBus} from '@/helper/EventBus'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://localhost:7000/api';
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
// axios.defaults.headers.post['Content-Type'] = 'application/json';

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || "https://payment.apaeasy.com/api",
  // baseURL: process.env.baseURL || process.env.apiUrl || "http://localhost:7000/api",
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    EventBus.$emit("toggle",true)
    const token = localStorage.getItem('token')
    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }


    return config;
  },
  function(error) {
    // Do something with request error
    EventBus.$emit("toggle",false)
    return Promise.reject(error.message);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    EventBus.$emit("toggle",false)
    return response.data;
  },
   async function(error) {
    // Do something with response error
     EventBus.$emit("toggle",false)

    if(error.response.status==401){
     await store.dispatch('LogOut')
    }

    console.log(error.message)
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
